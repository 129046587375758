:root {
  --primary-button-color: rgba(0, 52, 76, 1);
  --primary-button-hovered: rgba(0, 52, 76, 0.8);
  --secondary-button-hovered: #EBEFF1;
  --link-color: #337ab7;
  --link-visited: #477cb4;
  --grey-text: #868e96;
}

body,
html {
  font-family: Roboto, Helvetica Neue, sans-serif;
}

.login-pf body {
  background: white;
  background-image: url('');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

body.globalx {
  background-image: url('../img/login_bg.jpg');
}

.login-pf-page {
  min-height: 35em;
  padding-top: 10em;
}

.login-pf-page .card-pf {
  border-top: 0px;
  border-radius: 4px;
  padding: 30px;
  -webkit-box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, .12), -1px 0 2px 0 rgba(0, 0, 0, .12), 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
}

#kc-header {
  display: none;
}

#kc-page-title {
  display: none;
}

.login-pf-header img {
  display: block;
  margin: auto;
  height: 100%;
  width: auto;
}

#kc-form-buttons {
  text-align: center !important;
}

#enterprise-login-buttons {
  text-align: center !important;
}

#kc-reset-password-form #contact {
  margin: 20px;
  margin-bottom: -20px;
}

#kc-reset-password-form div:last-child::after {
  display: none;
}

a:visited {
  color: var(--link-visited);
}

a:link {
  color: var(--link-color);
}

.btm-lg-push {
  margin-top: 30px;
}

.btm-md-push {
  margin-top: 5px;
}

.text-info {
  color: var(--grey-text);
}

ul.inline-styless-list {
  padding-left: 0px;
  display: inline;
}

.inline-styless-list>li {
  display: inline;
  padding-left: 1px;
}

.txt-gray {
  color: var(--grey-text);
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
